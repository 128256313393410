import { Container } from '../../core/layout/Layout';
import Button from '../../core/buttons/Button';
import React, { ReactNode } from 'react';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import { graphql, useStaticQuery } from 'gatsby';
import { UgoPharmaPdfDownloadButtonQuery } from '../../../../generated/graphql-types';
import ContactFormButton from '../ContactFormButton/ContactFormButton';
import { convertToBgImage } from 'gbimage-bridge';

const firstPartstyles = {
  half: `w-full py-16 md:flex-grow md:w-1/2`,
  twoThirds: `w-full py-16 md:flex-grow md:w-2/3`,
};

const secondPartStyles = {
  half: `w-full md:w-1/2 h-0 md:h-auto md:flex-grow`,
  oneThird: `w-full md:w-1/3 h-0 md:h-auto md:flex-grow`,
};

const firstPartContainerStyles = {
  half: `max-w-xl! space-y-8`,
  twoThirds: `max-w-3xl! space-y-8`,
};

const wrapperStyles = {
  withBottomMargin: `grid grid-cols-1 md:flex md:flex-wrap mb-16`,
  withoutBottomMargin: `grid grid-cols-1 md:flex md:flex-wrap`,
};

const TwoPartLayout = ({
  children,
  layoutImage,
  halfLayout = false,
  removeBottomMargin = false,
  downloadPharmaPdfButton = false,
}: {
  children: ReactNode | ReactNode[];
  layoutImage: IGatsbyImageData;
  halfLayout?: boolean;
  removeBottomMargin?: boolean;
  downloadPharmaPdfButton?: boolean;
}) => {
  const firstPartSelectedStyle = halfLayout
    ? firstPartstyles.half
    : firstPartstyles.twoThirds;
  const secondPartSelectedStyle = halfLayout
    ? secondPartStyles.half
    : secondPartStyles.oneThird;
  const firstPartContainerSelectedStyle = halfLayout
    ? firstPartContainerStyles.half
    : firstPartContainerStyles.twoThirds;
  const wrapperSelectedStyle = removeBottomMargin
    ? wrapperStyles.withoutBottomMargin
    : wrapperStyles.withBottomMargin;

  const image = getImage(layoutImage);

  // Use like this:
  const bgImage = convertToBgImage(image);

  return (
    <div className={wrapperSelectedStyle}>
      <div
        className={firstPartSelectedStyle}
        style={{ backgroundColor: '#FEF7EB' }}
      >
        <Container className={firstPartContainerSelectedStyle}>
          {children}
          <div className="flex justify-center md:justify-start">
            {downloadPharmaPdfButton ? (
              <UgoPharmaPdfDownloadButton />
            ) : (
              <ContactFormButton />
            )}
          </div>
        </Container>
      </div>
      <div className={secondPartSelectedStyle}>
        <BackgroundImage {...bgImage} className="h-full" />
      </div>
    </div>
  );
};

export default TwoPartLayout;

const UgoPharmaPdfDownloadButton = () => {
  const pharmaPdfDownloadButtonData = useStaticQuery<
    UgoPharmaPdfDownloadButtonQuery
  >(graphql`
    query UgoPharmaPdfDownloadButton {
      pharmaFile: file(relativePath: { eq: "UGO-pharma.pdf" }) {
        publicURL
      }
    }
  `);
  return (
    <>
      <a href={pharmaPdfDownloadButtonData.pharmaFile.publicURL} download>
        <Button variant="filled" color="primary">
          Scarica la presentazione
        </Button>
      </a>
    </>
  );
};
