import React from 'react';
import { Container } from '../../core/layout/Layout';
import Button from '../../core/buttons/Button';
import { graphql, useStaticQuery } from 'gatsby';
import { BrochureBannerQuery } from '../../../../generated/graphql-types';

const BrochureBanner = () => {
  const brochureBannerData = useStaticQuery<BrochureBannerQuery>(graphql`
    query BrochureBanner {
      brochureFile: file(relativePath: { eq: "UGO-brochure.pdf" }) {
        publicURL
      }
    }
  `);
  return (
    <div
      style={{
        height: 'auto',
        backgroundImage: 'linear-gradient(to right, #AA66FF, #FFA500)',
      }}
    >
      <Container
        className={
          'grid gap-8 justify-center md:flex md:justify-between md:items-center py-16'
        }
      >
        <div className="grid gap-2 text-white text-center md:text-left">
          <h4 className="text-h4-mobile md:text-h4 tracking-h4 font-bold">
            Sei curioso di approfondire?
          </h4>
          <p className="text-lg font-semibold">
            Scarica la brochure del servizio e scrivi per tutte le domande che
            vuoi.
          </p>
        </div>
        <div className="flex justify-center">
          <a href={brochureBannerData.brochureFile.publicURL} download>
            <Button variant={'outlined'} color={'primary'}>
              Scarica la brochure
            </Button>
          </a>
        </div>
      </Container>
    </div>
  );
};

export default BrochureBanner;
